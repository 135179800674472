import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import ContactInfo from "../components/contact/contact-info.js"

const Contact = () => {
    // Contact page data
    const ql_contact = useStaticQuery(graphql`
        query {
            contentfulContactInfo {
                title
                contactInfo {
                    json
                }
            }
        }
    `)
    return (
        <Layout>
            <SEO title="Contact" />
            <ContactInfo contactData={ql_contact.contentfulContactInfo} />
        </Layout>
    )
}

export default Contact
