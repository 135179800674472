import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Style from "./contact.module.scss"

/**
 * Events (Default): Component for the content
 * of the contact page
 */
const ContactInfo = props => {
  return (
    <div className={Style.contactPane}>
      <h2>CONTACT & ADMINISTRATION</h2>
      <hr />
      <br />
      {documentToReactComponents(
        props.contactData.contactInfo.json,
        contact_options()
      )}
      <br />
      {"Website designed by "}
      <a
        href="http://www.maxardito.com"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Max Ardito
      </a>
      <br />
      <br />
      <a href="http://eepurl.com/uJ9l1">
        <b>{"Sign up for Wet Ink's mailing list HERE"}</b>
      </a>
      <br />
      <br />
    </div>
  )
}

export default ContactInfo

/* =================================================== */

/**
 * contact_options: Default rich text parsing options
 * for the contact page
 */
const contact_options = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p style={{ margin: 0, padding: 0 }}>{children}</p>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <p style={{ margin: 0, padding: 0 }}>{children}</p>
      },
    },
  }
  return option_node
}
